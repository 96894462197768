import type { WorkerInitMessage, WorkerMessageFunction, WorkerFunctionBroadcastMessage, ExecutableWorkerFunctions, ExecutableUIFunctionsImplementation } from "./types.ts";

/**
 * Message channel controller on the UI thread
 */
export class UIMessageChannel {
    /** Message channel object */
    private _messageChannel: MessageChannel | null = null;
    /** Executable functions by the broadcast channel */
    private _functions: ExecutableUIFunctionsImplementation;
    /** Callbacks store */
    private _callbacks: Record<string, Function> = {};
    private _worker: Worker | null = null;
    private _port2Attached: boolean = false;
    /** On connected callback */
    private _onConnected: () => void;

    id: string;

    get initialized() {
        return this._messageChannel != null && this._worker != null && this._port2Attached;
    }

    constructor(pOptions: {
        id: string,
        onConnected: () => void,
        functions: ExecutableUIFunctionsImplementation,
        connectDelay?: number,
        targetOrigin?: string
    }) {
        this.id = pOptions.id;
        this._functions = pOptions.functions;
        this._onConnected = pOptions.onConnected;
    }

    /** 
     * Initializes the broadcast channel on a loaded iframe. 
     * Should only be called after the onLoad event was fired for the iframe.
     * */
    connect(pWorker: Worker) {
        let promiseRes = (_value: boolean) => { };
        const promise = new Promise<boolean>((res) => {
            promiseRes = res;
        });
        if (this._messageChannel == null) {
            this._messageChannel = new MessageChannel();
            this._messageChannel.port1.onmessage = this._onMessage.bind(this);
            this._worker = pWorker;

            if (this._worker && this._messageChannel) {
                try {
                    const payload: WorkerInitMessage = {
                        type: 'init',
                        workerId: this.id,
                        importMaps: {
                            './worker.WorkerChannel.ts': import.meta.resolve('./worker.WorkerChannel.ts')
                        }
                    };
                    this._worker.postMessage(payload, [this._messageChannel.port2]);
                    promiseRes(this.initialized);
                } catch (ex) {
                    promiseRes(false);
                    console.log('MessageChannel: Failed to establish connection');
                    console.warn(ex);
                }
            }
        } else {
            console.warn('MessageChannel already initialized')
            return Promise.resolve(false);
        }
        return promise;
    }

    /** Closes the message channel */
    close() {
        if (this._messageChannel) {
            console.log('MessageChannel: Closing connection');
            this._messageChannel.port1.close();
            this._messageChannel.port2.close();
            this._messageChannel = null;
            this._worker = null;
        } else {
            console.warn('MessageChannel already closed');
        }
    }

    /**
     * Will broadcast function execution on the channel
     * @param {string} pName Name of the function to execute on the receiver
     * @param {string} pArgument Optional argument that will be passed to the executed function, must be string type
     * @param {number} pTimeout The timeout after which the promise will reject if no response is received
     */
    async execute<K extends keyof ExecutableWorkerFunctions>(pName: K, pArgument?: ExecutableWorkerFunctions[K]['argument'], pTimeout: number = 10000): Promise<ExecutableWorkerFunctions[K]['result']> {
        const uid = crypto.randomUUID();
        const messageObject: WorkerFunctionBroadcastMessage = {
            operation: 'execute',
            name: pName,
            payload: pArgument,
            meta: {
                uid: uid,
            }
        };
        let promiseRes: Function;
        let promiseRej: Function;
        const promise = new Promise<any>((res, rej) => {
            promiseRes = res;
            promiseRej = rej;
        });
        let timeoutDebounce: number | undefined = undefined;
        if (pTimeout !== -1) {
            timeoutDebounce = setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Message Channel timeout'));
            }, pTimeout);
        }
        this._callbacks[uid] = (success: boolean, result?: string) => {
            if (timeoutDebounce) {
                clearTimeout(timeoutDebounce);
            }
            delete this._callbacks[uid];
            if (!success) {
                promiseRej(new Error(result ?? 'Recieved failed status'));
            } else {
                promiseRes(result);
            }
        };
        this._messageChannel?.port1.postMessage(messageObject);
        return promise;
    }

    private async _onMessage(e: MessageEvent) {
        const message = e.data;
        if (!message) {
            console.warn('Received empty broadcast message', message);
            return;
        }
        const messageObject = message as WorkerFunctionBroadcastMessage;
        switch (messageObject.operation as typeof messageObject.operation | 'connected') {
            case 'connected':
                this._port2Attached = true;
                console.log('[UIChannel]: Connection with NodeData worker established');
                this._onConnected();
                break;
            case 'execute':
                let responseObject = {
                    operation: 'callback',
                    meta: {
                        uid: messageObject.meta.uid,
                    }
                } as WorkerFunctionBroadcastMessage;
                if (messageObject.name && typeof this._functions[messageObject.name] === 'function') {
                    let result: string | undefined;
                    let success: boolean;
                    try {
                        result = await this._functions[messageObject.name](messageObject.payload);
                        success = true;
                    } catch (ex: any) {
                        result = ex?.message ?? ex;
                        success = false;
                    }
                    responseObject.payload = result;
                    responseObject.success = success;
                    this._messageChannel?.port1.postMessage(responseObject);
                } else {
                    responseObject.payload = `Could not execute function with name: ${messageObject.name}`;
                    responseObject.success = false;
                    this._messageChannel?.port1.postMessage(responseObject);
                }
                break;
            case 'callback':
                const uid = messageObject.meta.uid;
                const callback = this._callbacks[uid];
                if (typeof callback === 'function') {
                    callback(messageObject.success, messageObject.payload);
                }
                break;
        }
    }
}